import PropTypes from 'prop-types';

import styled from 'styled-components';
import Loading from '../components/loading';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { Colors } from '../theme';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getLocalStorage, STORAGE } from '../utils/storage';

const Init = ({ children }) => {
  const { search } = useLocation();
  const { isLoading } = useWeb3Auth();
  const searchParams = new URLSearchParams(search);
  const actionType = searchParams.get('action_type');
  const typeLogin = getLocalStorage(STORAGE.LOGIN_TYPE);

  return (!isLoading) ? (
    children
  ) : (
    (actionType == 'logout' && typeLogin === "IMMUTABLE") ? (
      <PreloadingStyled>
        <Loading size="full-screen" />
      </PreloadingStyled>
    ) : (
      <></>
    )
  );
};

Init.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Init;

const PreloadingStyled = styled.div`
  background-color: ${Colors.BOX_BACKGROUND};
`;
