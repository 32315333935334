import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Loading from '../components/loading';
// import Page from '../components/page';
import { STORAGE, getLocalStorage, setLocalStorage } from '../utils/storage';
import { ROUTE_OPTION } from './constants';
import Header from './header';
import { Colors } from '../theme';

const Home = lazy(() => import('../pages/home'));
const CreateWallet = lazy(() => import('../pages/create-wallet'));
const NotFound = lazy(() => import('../pages/not-found'));

const PreloadingStyled = styled.div`
  background-color: ${Colors.BOX_BACKGROUND};
`;

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition() || getLocalStorage(STORAGE.ACCESS_TOKEN);

  if (condition) return <Route {...props} />;
  return <Redirect to={redirect} />;
};
PrivateRoute.propTypes = {
  condition: PropTypes.func,
  redirect: PropTypes.string,
};

@withRouter
class Routes extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  _renderLazyComponent = (LazyComponent, params) => props => <LazyComponent {...props} {...params} />;
  componentDidMount() {
    sessionStorage.setItem('isFirstLogin', true);
  }

  _convertPath = path => {
    if (path) {
      const pathSplit = path?.split('/');
      return pathSplit?.[0] || path;
    }
    return path;
  };

  _getDetailRoute = () => {
    const { location } = this.props;
    const currentPath = location?.pathname;
    let pathName = currentPath.slice(4, currentPath.length);
    const detail = ROUTE_OPTION?.find(item => {
      return item?.path === this._convertPath(pathName);
    });
    return detail;
  };

  render() {
    const currentLang = getLocalStorage(STORAGE.CURRENT_LANG);
    if (!currentLang) {
      setLocalStorage(STORAGE.CURRENT_LANG, 'en');
    }
    return (
      <VerticalBox>
        <Header />
        <Suspense
          fallback={
            <PreloadingStyled>
              <Loading size="full-screen" />
            </PreloadingStyled>
          }
        >
          <Switch>
            <Route exact path="/:lang/" component={this._renderLazyComponent(Home)} />
            <Route exact path="/:lang/create" component={this._renderLazyComponent(CreateWallet)} />
            <Route path="/:lang/not-found" component={this._renderLazyComponent(NotFound)} />
            <Redirect to={`/${['en', 'ja'].includes(currentLang) ? currentLang : 'en'}/`} />
          </Switch>
        </Suspense>
      </VerticalBox>
    );
  }
}

export default Routes;
